<template>
    <div class="d-block w-100">
        <div class="d-flex align-center justify-space-around flex-wrap">
            <div class="d-flex flex-column align-stretch flex-shrink-0 my-2 mx-4">
                <div class="d-flex align-center">
                    <img class="d-inline-block mr-2" src="/img/scroll_48.png" style="width: 32px;">
                    <h4 v-if="activeOffer.price" class="flex-grow-1 font-basic">
                        {{ Number(activeOffer.price) | localFormatNumber }}
                        <span class="d-inline-block d-md-none d-lg-inline-block">SCRL</span>
                    </h4>
                    <v-skeleton-loader
                        v-else
                        class="flex-grow-1 mx-6"
                        height="40px"
                        type="text, text"
                    />
                </div>
                <div v-if="amountRelevant" class="d-flex justify-space-between">
                    <h4>Quantity:</h4>
                    <h4 class="text-lowercase">{{ activeOffer.amount }} pcs</h4>
                </div>
            </div>
            <main-button v-if="!isOfferOwner" :on-click="openPopup" class="open-popup-btn">
                <span class="d-none d-sm-flex">Buy</span>
                <span class="d-flex d-sm-none">Buy</span>
            </main-button>
            <asset-cancel-offer-popup v-if="isOfferOwner" class="open-popup-btn"
                                      :activeOffer="activeOffer"
                                      :assetDTO="assetDTO"
            />
        </div>

        <dialog-box
            :model="popupOpen"
            :title="`Buy ${assetType.toLowerCase()}`"
            width="500"
            v-on:dialogClosed="popupOpen=!popupOpen"
        >
            <v-form v-model="isFormValid">
                <div class="mt-5">
                    <v-row no-gutters>
                        <v-col cols="12" sm="5">
                            <div class="d-flex justify-center">
                                <mini-wizard
                                    v-if="assetType === assetTypeEnum.WIZARD"
                                    :item="assetDTO"
                                />
                                <mini-land
                                    v-else-if="assetType === assetTypeEnum.LAND"
                                    :item="assetDTO"
                                />
                                <mini-enhancement
                                    v-else-if="assetType === assetTypeEnum.ENHANCEMENT"
                                    :item="assetDTO"
                                    :amount="activeOffer.amount"
                                />
                            </div>
                        </v-col>
                        <v-col class="text-center align-center" cols="12" sm="7">
                            <v-container class="caption">
                                <v-row v-for="prop in offerProperties"
                                       class="text-left" dense>
                                    <v-col cols="4">{{ prop.name }}</v-col>
                                    <v-col class="font-weight-bold">{{ prop.value }}</v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="5">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        <main-button
                                            :disabled="!isWalletConnected || !isFormValid || scrlAllowed"
                                            :loading="!scrlAllowanceChecked || scrlAllowingInProgress"
                                            :on-click="()=>approveTokenRequest()"
                                        >
                                            <v-icon v-if="scrlAllowed" small>mdi-check</v-icon>
                                            <v-icon v-else small>mdi-alert-circle-outline</v-icon>
                                            Allow SCRL
                                        </main-button>
                                    </div>
                                </template>
                                <div>
                                    <ul>
                                        <li v-for="(tooltip, i) in allowanceTooltips" :key="'all-' + i">
                                            {{ tooltip }}
                                        </li>
                                    </ul>
                                </div>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="7">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        <main-button
                                            :disabled="!isWalletConnected || !isFormValid || confirmButtonDisabled"
                                            :loading="transactionInProgress"
                                            :on-click="()=>handleConfirmationClick()"
                                        >
                                            Accept offer
                                        </main-button>
                                    </div>
                                </template>
                                <div>
                                    <span v-if="!confirmButtonDisabled">Click confirm buying {{ assetType }}</span>
                                    <ul v-else>
                                        <li v-for="(error, i) in validationErrors" :key="'err-' + i">{{ error }}</li>
                                    </ul>
                                </div>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </dialog-box>
    </div>
</template>

<script>

import MainButton from "../ui/main-button";
import DialogBox from "../dialog-box";
import MiniEnhancement from "../assets/enhancements/mini-enhancement";
import InputAmount from "../input-amount";
import MiniWizard from "../assets/wizards/mini-wizard";
import {AssetType} from "@/classes/asset/AssetType";
import MiniLand from "@/components/assets/lands/mini-land";
import MarketService from "@/services/marketService";
import {addDecimals} from "@/helpers/tokenHelper";
import MarketContract from "@/services/contracts/marketContract";
import OfferDTO from "@/classes/market/OfferDTO";
import AssetService from "@/services/assetService";
import {OfferStatus} from "@/classes/market/OfferStatus";
import {UserWalletError} from "../../errors/WalletErrors";
import AssetCancelOfferPopup from "./asset-cancel-offer-popup";

export default {
    components: {AssetCancelOfferPopup, MiniLand, MiniWizard, InputAmount, MiniEnhancement, DialogBox, MainButton},
    props: {
        activeOffer: OfferDTO
    },
    data() {
        return {
            marketContract: undefined,
            decimalPrecision: 100,
            assetService: undefined,
            marketService: undefined,
            assetTypeEnum: AssetType,
            marketFee: undefined,
            transactionInProgress: false,
            popupOpen: false,
            isFormValid: false,
            scrlAllowed: false,
            scrlAllowanceChecked: false,
            scrlAllowingInProgress: false,
        }
    },
    async mounted() {
        if (this.isWalletConnected) {
            this.init();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.init();
            });
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        buyerAddress() {
            return this.web3.address?.toLowerCase()
        },
        amountRelevant() {
            return [AssetType.ENHANCEMENT].includes(this.assetType);
        },
        amountAsNumber() {
            return Number(this.activeOffer.amount);
        },
        hasEnoughBalanceToBuy() {
            return this.web3.balance >= addDecimals(this.activeOffer.price);
        },
        assetDTO() {
            return this.activeOffer.assetDTO
        },
        assetType() {
            return this.activeOffer.type
        },
        offerProperties() {
            return [
                this.amountRelevant && {name: 'Amount', value: `${this.amountAsNumber.toLocaleString()} pcs`},
                {
                    name: 'Price',
                    value: `${this.$options.filters.localFormatNumber(Number(this.activeOffer.price))} SCRL`
                },
                {name: 'Started', value: `${new Date(this.activeOffer.created_on).toLocaleString()}`},
            ]
        },
        isOfferOwner() {
            return this.isWalletConnected && this.buyerAddress?.toLowerCase() === this.activeOffer.creator?.toLowerCase();
        },
        validations() {
            return [
                {
                    rule: !this.isWalletConnected,
                    warning: 'You need to connect wallet to buy something'
                },
                {
                    rule: this.isWalletConnected && !this.scrlAllowanceChecked,
                    warning: 'Allowance validation in progress'
                },
                {
                    rule: this.isWalletConnected && this.scrlAllowanceChecked && !this.scrlAllowed,
                    warning: 'You need to allow SCRLs'
                },
                {
                    rule: this.isOfferOwner,
                    warning: 'You are trying to buy your own stuff'
                },
                {
                    rule: this.isWalletConnected && !this.hasEnoughBalanceToBuy,
                    warning: 'You dont have enough SCRL to buy that'
                },
            ]
        },
        allowanceRules() {
            return [
                {
                    rule: !this.isWalletConnected,
                    warning: 'You need to connect wallet to allow SCRL'
                },
                {
                    rule: this.isWalletConnected && this.scrlAllowed,
                    warning: 'Enough SCRLs already allowed'
                },
                {
                    rule: this.isWalletConnected && !this.scrlAllowed,
                    warning: 'Click to allow marketplace to take SCRL as payment'
                },
                {
                    rule: this.isWalletConnected && this.scrlAllowingInProgress,
                    warning: 'Allowing SCRLs, check your wallet for details'
                },
                {
                    rule: this.isWalletConnected && !this.scrlAllowanceChecked,
                    warning: 'Checking your SCRL allowance'
                },
            ]
        },
        validationErrors() {
            return [
                ...this.validations
            ]
                .filter(v => v.rule)
                .map(v => v.warning);
        },
        allowanceTooltips() {
            return [
                ...this.allowanceRules
            ]
                .filter(v => v.rule)
                .map(v => v.warning);

        },
        confirmButtonDisabled() {
            return this.validationErrors.length > 0
        },
    },
    methods: {
        init() {
            this.initializeAssetService()
            this.initializeMarketService()
            this.initializeMarketContract();
            this.checkTokenAllowance();

            this.marketFee = this.marketService.getMarketFee();
        },
        initializeAssetService() {
            this.assetService = new AssetService(this.web3, this.flashMessage);
        },
        initializeMarketService() {
            this.marketService = new MarketService();
        },
        initializeMarketContract() {
            this.marketContract = new MarketContract(this.web3, this.assetService);
        },
        async checkTokenAllowance() {
            return this.marketContract.tokenSpendCheckAllowance()
                .then((result) => {
                    //ToDo: check if enough scrl allowed to buy (after contract and BE integration
                    this.scrlAllowed = result >= addDecimals(10000000);
                    this.scrlAllowanceChecked = true
                });
        },
        async approveTokenRequest() {
            this.scrlAllowingInProgress = true
            this.marketContract.tokenSpendApprove()
                .then((_) => {
                    this.scrlAllowed = true;
                    this.$forceUpdate(); // update buttons status
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        console.error(error);
                        this.flashMessage.show({
                            status: 'error',
                            message: 'Approving SCRL allowance error'
                        });
                    }
                })
                .finally(() => {
                    this.scrlAllowingInProgress = false;
                });
        },
        openPopup() {
            this.popupOpen = true;
        },
        async handleConfirmationClick() {
            this.transactionInProgress = true;

            this.marketContract.takeOffer(this.activeOffer.id, this.activeOffer.price)
                .then(async (_) => {
                    await this.marketService.waitForOfferStatus(
                        this.activeOffer.id,
                        OfferStatus.TAKEN);
                    await this.$store.dispatch('updateWeb3Balance');
                    this.flashMessage.show({
                        status: 'success',
                        message: 'Offer accepted'
                    });
                    const loadedEvent = new CustomEvent('reload-view');
                    window.dispatchEvent(loadedEvent);
                    this.popupOpen = false;
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        console.error(error);
                        this.flashMessage.show({
                            status: 'error',
                            message: 'Offer accepting failed'
                        });
                        this.popupOpen = false;
                    }
                })
                .finally(() => {
                    this.transactionInProgress = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.open-popup-btn {
    width: 40%;
    min-width: 120px;
}
</style>

<template>
    <div class="d-block w-100">
        <main-button :on-click="openPopup" class="open-popup-btn">
            <span class="d-none d-sm-flex">Create offer</span>
            <span class="d-flex d-sm-none">Create</span>
        </main-button>

        <dialog-box
            :model="popupOpen"
            :title="`Sell ${assetType.toLowerCase()}`"
            width="500"
            v-on:dialogClosed="popupOpen=!popupOpen"
        >
            <v-form v-model="isFormValid">

                <div class="mt-5">
                    <v-row no-gutters>
                        <v-col cols="12" sm="5">
                            <div class="d-flex justify-center">
                                <mini-wizard
                                    v-if="assetType === assetTypeEnum.WIZARD"
                                    :item="assetDTO"
                                />
                                <mini-land
                                    v-else-if="assetType === assetTypeEnum.LAND"
                                    :item="assetDTO"
                                />
                                <mini-enhancement
                                    v-else-if="assetType === assetTypeEnum.ENHANCEMENT"
                                    :item="assetDTO"
                                    :amount="amount"
                                />
                            </div>
                        </v-col>
                        <v-col class="text-center align-center" cols="12" sm="7">
                            <v-container class="caption">
                                <v-row v-for="prop in offerProperties"
                                       class="text-left" dense>
                                    <v-col cols="4">{{ prop.name }}</v-col>
                                    <v-col class="font-weight-bold">{{ prop.value }}</v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col align-self="end" cols="12" order="12" order-sm="0" sm="5">
                            <input-amount
                                v-if="amountLimited"
                                :id="assetDTO.id"
                                :init-value="1"
                                :max-value="maxAmount"
                                :suffix="'/ '+maxAmount"
                                class="w-100 pr-4"
                                label="Amount"
                                @valueChanged="selectedAmountChanged"
                            ></input-amount>
                        </v-col>
                        <v-col cols="12" sm="7">
                            <div class="d-flex align-center">
                                <v-text-field
                                    v-model="sellPrice"
                                    :rules="priceRules"
                                    :counter="priceMaxLength"
                                    type="number"
                                    hide-spin-buttons
                                    label="Offer price"
                                    suffix="SCRL"
                                    class="d-inline-block"
                                    color="#ccc"
                                ></v-text-field>
                                <img class="d-inline-block ml-2" src="/img/scroll_48.png" style="width: 32px;">
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="validationWarnings.length > 0" cols="12">
                            <v-alert
                                class="mb-0"
                                dense
                                outlined
                                text
                                type="error"
                            >
                                <ul>
                                    <li v-for="(error, i) in validationWarnings" :key="'err-' + i">{{ error }}</li>
                                </ul>
                            </v-alert>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        <main-button
                                            :disabled="allowButtonDisabled"
                                            :loading="allowButtonLoading"
                                            :on-click="()=>handleAllowButton()"
                                        >
                                            <v-icon v-if="assetAllowed" small>mdi-check</v-icon>
                                            <v-icon v-else small>mdi-alert-circle-outline</v-icon>
                                            Allow Asset
                                        </main-button>
                                    </div>
                                </template>
                                <div>
                                    <span v-if="!allowButtonDisabled">Click to allow {{ assetType.toLowerCase() }}s for market contract</span>
                                    <ul v-else>
                                        <li v-for="(tooltip, i) in allowanceWarnings" :key="'all-' + i">
                                            {{ tooltip }}
                                        </li>
                                    </ul>
                                </div>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="7">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        <main-button
                                            :disabled="confirmButtonDisabled"
                                            :loading="transactionInProgress"
                                            :on-click="()=>handleConfirmationClick()"
                                        >
                                            Create offer
                                        </main-button>
                                    </div>
                                </template>
                                <div>
                                    <span v-if="!confirmButtonDisabled">Create offer for <b>{{
                                            assetDTO.name
                                        }}</b>
                                    </span>
                                    <ul v-else>
                                        <li v-for="(error, i) in validationWarnings" :key="'err-' + i">{{ error }}</li>
                                    </ul>
                                </div>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </dialog-box>
    </div>
</template>

<script>

import MainButton from "../ui/main-button";
import DialogBox from "../dialog-box";
import MiniEnhancement from "../assets/enhancements/mini-enhancement";
import InputAmount from "../input-amount";
import MiniWizard from "../assets/wizards/mini-wizard";
import AssetDTO from "@/classes/asset/AssetDTO";
import {AssetType} from "@/classes/asset/AssetType";
import MiniLand from "@/components/assets/lands/mini-land";
import MarketService from "@/services/marketService";
import MarketContract from "@/services/contracts/marketContract";
import AssetService from "@/services/assetService";
import {OfferStatus} from "@/classes/market/OfferStatus";
import {UserWalletError} from "../../errors/WalletErrors";

export default {
    components: {MiniLand, MiniWizard, InputAmount, MiniEnhancement, DialogBox, MainButton},
    props: {
        assetDTO: AssetDTO,
        assetType: AssetType,
        maxAmount: {
            type: Number,
            default: undefined,
        }
    },
    data() {
        return {
            /**
             * @type {MarketContract}
             */
            marketContract: undefined,
            decimalPrecision: 100,
            assetService: undefined,
            marketService: undefined,
            assetTypeEnum: AssetType,
            minimumSellValue: undefined,
            marketFee: undefined,
            transactionInProgress: false,
            popupOpen: false,
            sellPrice: undefined,
            priceRules: {},
            isFormValid: false,
            assetAllowanceChecking: false,
            assetAllowanceInProgress: false,
            assetAllowed: false,
            amount: 1,
            priceMaxLength: 9,

        }
    },
    mounted() {
        if (this.isWalletConnected) {
            this.init();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.init();
            });
        }
    },
    computed: {
        marketFeePercentage() {
            return this.marketFee * 100;
        },
        amountAsNumber() {
            return Math.round(Number(this.amount));
        },
        sellPriceAsNumber() {
            return Math.round(Number(this.sellPrice));
        },
        sellerProfit() {
            return this.sellPriceAsNumber * (1 - this.marketFee);
        },
        roundedSellerProfit() {
            return Math.round(this.sellerProfit * this.decimalPrecision) / this.decimalPrecision
        },
        treasuryProfit() {
            return this.sellPriceAsNumber * this.marketFee;
        },
        roundedTreasuryProfit() {
            return Math.round(this.treasuryProfit * this.decimalPrecision) / this.decimalPrecision
        },
        amountLimited() {
            return this.maxAmount > 0;
        },
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        offerProperties() {
            return [
                this.amountLimited && {name: 'Amount', value: `${this.amountAsNumber.toLocaleString()} pcs`},
                {name: 'Price', value: `${this.$options.filters.localFormatNumber(this.sellPriceAsNumber)} SCRL`},
                {name: 'Fee', value: `${this.marketFeePercentage.toLocaleString()} %`},
                {name: 'You Get', value: `${this.$options.filters.localFormatNumber(this.roundedSellerProfit)} SCRL`},
            ];
        },
        allowanceRules() {
            return [
                {
                    rule: !this.isWalletConnected,
                    warning: 'You need to connect wallet to allow SCRL'
                },
                {
                    rule: this.isWalletConnected && this.assetAllowed,
                    warning: `Already allowed ${(this.assetType).toLowerCase()}s`
                },
                {
                    rule: this.isWalletConnected && this.assetAllowanceInProgress,
                    warning: `Allowing ${(this.assetType).toLowerCase()}s, check your wallet for details`
                },
                {
                    rule: this.isWalletConnected && this.assetAllowanceChecking,
                    warning: 'Checking your SCRL allowance'
                },
            ]
        },
        validationsRules() {
            return [
                {
                    rule: !this.isWalletConnected,
                    warning: 'You need to connect wallet to buy something'
                },
                {
                    rule: this.isWalletConnected && this.assetAllowanceChecking,
                    warning: 'Allowance validation in progress'
                },
                {
                    rule: this.isWalletConnected && !this.assetAllowanceChecking && !this.assetAllowed,
                    warning: `You need to allow ${(this.assetType).toLowerCase()}s`
                },
                {
                    rule: this.isWalletConnected && this.transactionInProgress,
                    warning: `Offer creation in progress, check your wallet`
                },
                {
                    rule: !this.sellPrice,
                    warning: 'Sell price is required.'
                },
                {
                    rule: isNaN(this.sellPrice),
                    warning: 'Price is not a number'
                },
                {
                    rule: !(this.sellPriceAsNumber >= this.minimumSellValue),
                    warning: `Price must not be smaller than ${this.minimumSellValue?.toLocaleString()} SCRL.`
                },
                {
                    rule: !(this.sellPriceAsNumber.toString().length <= this.priceMaxLength),
                    warning: `Price length exceeds maximum.`
                },
                {
                    rule: !(Number(this.sellPrice) === Math.round(this.sellPrice)),
                    warning: `Price must not contain decimals.`
                },
                {
                    rule: !(Number(this.amount) === Math.round(this.amount)),
                    warning: `Amount must be whole numbers.`
                },
            ]
        },
        allowanceWarnings() {
            return [
                ...this.allowanceRules
            ]
                .filter(v => v.rule)
                .map(v => v.warning);
        },
        validationWarnings() {
            return [
                ...this.validationsRules
            ]
                .filter(v => v.rule)
                .map(v => v.warning);
        },
        allowButtonDisabled() {
            return this.allowanceWarnings.length > 0;
        },
        confirmButtonDisabled() {
            return this.validationWarnings.length > 0;
        },
        allowButtonLoading() {
            return Boolean(this.assetAllowanceChecking || this.assetAllowanceInProgress)
        },
    },
    methods: {
        init() {
            this.initializeAssetService()
            this.initializeMarketService()
            this.initializeMarketContract();
            this.checkAssetAllowance();

            this.marketFee = this.marketService.getMarketFee();
            this.minimumSellValue = this.marketService.getMinimumPriceFor(this.assetType);
            this.sellPrice = this.minimumSellValue * 6;
            this.priceRules = [
                value => !!value || 'Required.',
                value => !isNaN(value) || 'Price is not a number',
                value => value >= this.minimumSellValue || `Price must not be smaller than ${this.minimumSellValue.toLocaleString()} SCRL.`,
                value => value.length <= this.priceMaxLength || `Price length exceeds maximum.`,
            ];
        },
        initializeAssetService() {
            this.assetService = new AssetService(this.web3, this.flashMessage);
        },
        initializeMarketService() {
            this.marketService = new MarketService();
        },
        initializeMarketContract() {
            this.marketContract = new MarketContract(this.web3, this.assetService);
        },
        openPopup() {
            this.popupOpen = true;
        },
        selectedAmountChanged(obj) {
            this.amount = Number(obj.value);
        },
        async handleConfirmationClick() {
            this.transactionInProgress = true;

            this.marketContract.createOffer(this.assetType, this.assetDTO.id, this.amountAsNumber, this.sellPriceAsNumber)
                .then(async (blockchainResponse) => {
                    const offerId = blockchainResponse.events.OfferCreated.returnValues.offerId;
                    await this.marketService.waitForOfferStatus(
                        offerId,
                        OfferStatus.AVAILABLE);
                    this.flashMessage.show({
                        status: 'success',
                        message: 'Offer created'
                    });
                    this.popupOpen = false;
                    const loadedEvent = new CustomEvent('reload-view');
                    window.dispatchEvent(loadedEvent);
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        this.popupOpen = false;
                        console.error(error);
                        this.flashMessage.show({
                            status: 'error',
                            message: 'Offer creation failed'
                        });
                    }
                })
                .finally(() => {
                    this.transactionInProgress = false;
                });
        },
        checkAssetAllowance() {
            this.assetAllowanceChecking = true;

            this.marketContract.assetSpendCheckAllowance(this.assetType)
                .then((allowed) => {
                    this.assetAllowed = allowed;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.assetAllowanceChecking = false;
                });
        },
        handleAllowButton() {
            this.assetAllowanceInProgress = true;

            this.marketContract.assetSpendApprove(this.assetType)
                .then((_) => {
                    this.assetAllowed = true;
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.assetAllowanceInProgress = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.open-popup-btn.main-button {
    min-width: 120px;
}
</style>

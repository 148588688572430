var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-block w-100" },
    [
      _c(
        "main-button",
        { staticClass: "open-popup-btn", attrs: { "on-click": _vm.openPopup } },
        [
          _c("span", { staticClass: "d-none d-sm-flex" }, [
            _vm._v("Create offer"),
          ]),
          _c("span", { staticClass: "d-flex d-sm-none" }, [_vm._v("Create")]),
        ]
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            model: _vm.popupOpen,
            title: `Sell ${_vm.assetType.toLowerCase()}`,
            width: "500",
          },
          on: {
            dialogClosed: function ($event) {
              _vm.popupOpen = !_vm.popupOpen
            },
          },
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.isFormValid,
                callback: function ($$v) {
                  _vm.isFormValid = $$v
                },
                expression: "isFormValid",
              },
            },
            [
              _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "5" } }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _vm.assetType === _vm.assetTypeEnum.WIZARD
                              ? _c("mini-wizard", {
                                  attrs: { item: _vm.assetDTO },
                                })
                              : _vm.assetType === _vm.assetTypeEnum.LAND
                              ? _c("mini-land", {
                                  attrs: { item: _vm.assetDTO },
                                })
                              : _vm.assetType === _vm.assetTypeEnum.ENHANCEMENT
                              ? _c("mini-enhancement", {
                                  attrs: {
                                    item: _vm.assetDTO,
                                    amount: _vm.amount,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center align-center",
                          attrs: { cols: "12", sm: "7" },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "caption" },
                            _vm._l(_vm.offerProperties, function (prop) {
                              return _c(
                                "v-row",
                                {
                                  staticClass: "text-left",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "4" } }, [
                                    _vm._v(_vm._s(prop.name)),
                                  ]),
                                  _c(
                                    "v-col",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v(_vm._s(prop.value))]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            "align-self": "end",
                            cols: "12",
                            order: "12",
                            "order-sm": "0",
                            sm: "5",
                          },
                        },
                        [
                          _vm.amountLimited
                            ? _c("input-amount", {
                                staticClass: "w-100 pr-4",
                                attrs: {
                                  id: _vm.assetDTO.id,
                                  "init-value": 1,
                                  "max-value": _vm.maxAmount,
                                  suffix: "/ " + _vm.maxAmount,
                                  label: "Amount",
                                },
                                on: { valueChanged: _vm.selectedAmountChanged },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12", sm: "7" } }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center" },
                          [
                            _c("v-text-field", {
                              staticClass: "d-inline-block",
                              attrs: {
                                rules: _vm.priceRules,
                                counter: _vm.priceMaxLength,
                                type: "number",
                                "hide-spin-buttons": "",
                                label: "Offer price",
                                suffix: "SCRL",
                                color: "#ccc",
                              },
                              model: {
                                value: _vm.sellPrice,
                                callback: function ($$v) {
                                  _vm.sellPrice = $$v
                                },
                                expression: "sellPrice",
                              },
                            }),
                            _c("img", {
                              staticClass: "d-inline-block ml-2",
                              staticStyle: { width: "32px" },
                              attrs: { src: "/img/scroll_48.png" },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _vm.validationWarnings.length > 0
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "mb-0",
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    text: "",
                                    type: "error",
                                  },
                                },
                                [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.validationWarnings,
                                      function (error, i) {
                                        return _c("li", { key: "err-" + i }, [
                                          _vm._v(_vm._s(error)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "5" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          _vm._b({}, "div", attrs, false),
                                          on
                                        ),
                                        [
                                          _c(
                                            "main-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.allowButtonDisabled,
                                                loading: _vm.allowButtonLoading,
                                                "on-click": () =>
                                                  _vm.handleAllowButton(),
                                              },
                                            },
                                            [
                                              _vm.assetAllowed
                                                ? _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-check")]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        "mdi-alert-circle-outline"
                                                      ),
                                                    ]
                                                  ),
                                              _vm._v(" Allow Asset "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("div", [
                                !_vm.allowButtonDisabled
                                  ? _c("span", [
                                      _vm._v(
                                        "Click to allow " +
                                          _vm._s(_vm.assetType.toLowerCase()) +
                                          "s for market contract"
                                      ),
                                    ])
                                  : _c(
                                      "ul",
                                      _vm._l(
                                        _vm.allowanceWarnings,
                                        function (tooltip, i) {
                                          return _c("li", { key: "all-" + i }, [
                                            _vm._v(" " + _vm._s(tooltip) + " "),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "7" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          _vm._b({}, "div", attrs, false),
                                          on
                                        ),
                                        [
                                          _c(
                                            "main-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.confirmButtonDisabled,
                                                loading:
                                                  _vm.transactionInProgress,
                                                "on-click": () =>
                                                  _vm.handleConfirmationClick(),
                                              },
                                            },
                                            [_vm._v(" Create offer ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("div", [
                                !_vm.confirmButtonDisabled
                                  ? _c("span", [
                                      _vm._v("Create offer for "),
                                      _c("b", [
                                        _vm._v(_vm._s(_vm.assetDTO.name)),
                                      ]),
                                    ])
                                  : _c(
                                      "ul",
                                      _vm._l(
                                        _vm.validationWarnings,
                                        function (error, i) {
                                          return _c("li", { key: "err-" + i }, [
                                            _vm._v(_vm._s(error)),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-block w-100" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-around flex-wrap" },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column align-stretch flex-shrink-0 my-2 mx-4",
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("img", {
                    staticClass: "d-inline-block mr-2",
                    staticStyle: { width: "32px" },
                    attrs: { src: "/img/scroll_48.png" },
                  }),
                  _vm.activeOffer.price
                    ? _c("h4", { staticClass: "flex-grow-1 font-basic" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("localFormatNumber")(
                                Number(_vm.activeOffer.price)
                              )
                            ) +
                            " "
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "d-inline-block d-md-none d-lg-inline-block",
                          },
                          [_vm._v("SCRL")]
                        ),
                      ])
                    : _c("v-skeleton-loader", {
                        staticClass: "flex-grow-1 mx-6",
                        attrs: { height: "40px", type: "text, text" },
                      }),
                ],
                1
              ),
              _vm.amountRelevant
                ? _c("div", { staticClass: "d-flex justify-space-between" }, [
                    _c("h4", [_vm._v("Quantity:")]),
                    _c("h4", { staticClass: "text-lowercase" }, [
                      _vm._v(_vm._s(_vm.activeOffer.amount) + " pcs"),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          !_vm.isOfferOwner
            ? _c(
                "main-button",
                {
                  staticClass: "open-popup-btn",
                  attrs: { "on-click": _vm.openPopup },
                },
                [
                  _c("span", { staticClass: "d-none d-sm-flex" }, [
                    _vm._v("Buy"),
                  ]),
                  _c("span", { staticClass: "d-flex d-sm-none" }, [
                    _vm._v("Buy"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.isOfferOwner
            ? _c("asset-cancel-offer-popup", {
                staticClass: "open-popup-btn",
                attrs: { activeOffer: _vm.activeOffer, assetDTO: _vm.assetDTO },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            model: _vm.popupOpen,
            title: `Buy ${_vm.assetType.toLowerCase()}`,
            width: "500",
          },
          on: {
            dialogClosed: function ($event) {
              _vm.popupOpen = !_vm.popupOpen
            },
          },
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.isFormValid,
                callback: function ($$v) {
                  _vm.isFormValid = $$v
                },
                expression: "isFormValid",
              },
            },
            [
              _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "5" } }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _vm.assetType === _vm.assetTypeEnum.WIZARD
                              ? _c("mini-wizard", {
                                  attrs: { item: _vm.assetDTO },
                                })
                              : _vm.assetType === _vm.assetTypeEnum.LAND
                              ? _c("mini-land", {
                                  attrs: { item: _vm.assetDTO },
                                })
                              : _vm.assetType === _vm.assetTypeEnum.ENHANCEMENT
                              ? _c("mini-enhancement", {
                                  attrs: {
                                    item: _vm.assetDTO,
                                    amount: _vm.activeOffer.amount,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center align-center",
                          attrs: { cols: "12", sm: "7" },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "caption" },
                            _vm._l(_vm.offerProperties, function (prop) {
                              return _c(
                                "v-row",
                                {
                                  staticClass: "text-left",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "4" } }, [
                                    _vm._v(_vm._s(prop.name)),
                                  ]),
                                  _c(
                                    "v-col",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v(_vm._s(prop.value))]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "5" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          _vm._b({}, "div", attrs, false),
                                          on
                                        ),
                                        [
                                          _c(
                                            "main-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  !_vm.isWalletConnected ||
                                                  !_vm.isFormValid ||
                                                  _vm.scrlAllowed,
                                                loading:
                                                  !_vm.scrlAllowanceChecked ||
                                                  _vm.scrlAllowingInProgress,
                                                "on-click": () =>
                                                  _vm.approveTokenRequest(),
                                              },
                                            },
                                            [
                                              _vm.scrlAllowed
                                                ? _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-check")]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        "mdi-alert-circle-outline"
                                                      ),
                                                    ]
                                                  ),
                                              _vm._v(" Allow SCRL "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("div", [
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.allowanceTooltips,
                                    function (tooltip, i) {
                                      return _c("li", { key: "all-" + i }, [
                                        _vm._v(" " + _vm._s(tooltip) + " "),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "7" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          _vm._b({}, "div", attrs, false),
                                          on
                                        ),
                                        [
                                          _c(
                                            "main-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  !_vm.isWalletConnected ||
                                                  !_vm.isFormValid ||
                                                  _vm.confirmButtonDisabled,
                                                loading:
                                                  _vm.transactionInProgress,
                                                "on-click": () =>
                                                  _vm.handleConfirmationClick(),
                                              },
                                            },
                                            [_vm._v(" Accept offer ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("div", [
                                !_vm.confirmButtonDisabled
                                  ? _c("span", [
                                      _vm._v(
                                        "Click confirm buying " +
                                          _vm._s(_vm.assetType)
                                      ),
                                    ])
                                  : _c(
                                      "ul",
                                      _vm._l(
                                        _vm.validationErrors,
                                        function (error, i) {
                                          return _c("li", { key: "err-" + i }, [
                                            _vm._v(_vm._s(error)),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
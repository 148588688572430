var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-block w-100" },
    [
      _c(
        "main-button",
        { staticClass: "open-popup-btn", attrs: { "on-click": _vm.openPopup } },
        [
          _c("span", { staticClass: "d-none d-sm-flex" }, [
            _vm._v("Cancel offer"),
          ]),
          _c("span", { staticClass: "d-flex d-sm-none" }, [_vm._v("Cancel")]),
        ]
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            model: _vm.popupOpen,
            title: `Cancel ${_vm.assetType.toLowerCase()} offer`,
            width: "500",
          },
          on: {
            dialogClosed: function ($event) {
              _vm.popupOpen = !_vm.popupOpen
            },
          },
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.isFormValid,
                callback: function ($$v) {
                  _vm.isFormValid = $$v
                },
                expression: "isFormValid",
              },
            },
            [
              _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "5" } }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _vm.assetType === _vm.assetTypeEnum.WIZARD
                              ? _c("mini-wizard", {
                                  attrs: { item: _vm.assetDTO },
                                })
                              : _vm.assetType === _vm.assetTypeEnum.LAND
                              ? _c("mini-land", {
                                  attrs: { item: _vm.assetDTO },
                                })
                              : _vm.assetType === _vm.assetTypeEnum.ENHANCEMENT
                              ? _c("mini-enhancement", {
                                  attrs: {
                                    item: _vm.assetDTO,
                                    amount: _vm.activeOffer.amount,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center align-center",
                          attrs: { cols: "12", sm: "7" },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "caption" },
                            _vm._l(_vm.offerProperties, function (prop) {
                              return _c(
                                "v-row",
                                {
                                  staticClass: "text-left",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "4" } }, [
                                    _vm._v(_vm._s(prop.name)),
                                  ]),
                                  _c(
                                    "v-col",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v(_vm._s(prop.value))]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "main-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    disabled: !_vm.isWalletConnected || !_vm.isFormValid,
                    loading: _vm.transactionInProgress,
                    "on-click": () => _vm.handleConfirmationClick(),
                  },
                },
                [_vm._v(" Cancel offer ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default class OfferApiFiltersDTO {
    /**
     * @type {Number[]}
     */
    offerIds;
    /**
     * @type {AssetType[]}
     */
    assetTypes;
    /**
     * @type {OfferStatus[]}
     */
    offerStatuses;
    /**
     * @type {Number[]}
     */
    assetIds;
    /**
     * @type {String[]}
     */
    creators;
    /**
     * @type {String[]}
     */
    takers;
    /**
     * @type {Number}
     */
    minPrice;
    /**
     * @type {Number}
     */
    maxPrice;
}


// noinspection DuplicatedCode

import {AssetType} from "@/classes/asset/AssetType";
import OfferDTO from "@/classes/market/OfferDTO";
import OfferApiFiltersDTO from "@/classes/filters/OfferApiFiltersDTO";
import {OfferStatus} from "@/classes/market/OfferStatus";
import {apiServiceVue} from "@/services/apiService.vue";

const assetMinimumPrices = new Map([
    [AssetType.WIZARD, 100000], // $1
    [AssetType.LAND, 600000], // $5
    [AssetType.ENHANCEMENT, 50000], // $0.5
    [AssetType.CARD, 50000], // $0.5
]);

const MARKETPLACE_URL = process.env.VUE_APP_MARKETPLACE_URL;
const OFFERS_URL = MARKETPLACE_URL + 'offers/';
const REFRESH_URL = MARKETPLACE_URL + 'refresh/';

export default class MarketService {

    /**
     *
     * @returns {number}
     */
    getMarketFee() {
        const feePercent = 5;
        return feePercent / 100;
    }

    /**
     *
     * @param {AssetType} assetType
     * @returns {number}
     */
    getMinimumPriceFor(assetType) {
        return assetMinimumPrices.get(assetType);
    }


    /**
     *
     * @return {Promise<void>}
     */
    async refreshEvents() {
        return await apiServiceVue.request(REFRESH_URL, 'POST', {});
    }

    /**
     *
     * @param {OfferApiFiltersDTO} filters
     * @return {Promise<OfferDTO[]>}
     */
    async getOffers(filters) {
        return await apiServiceVue.request(OFFERS_URL, 'POST', {filters: filters})
            .then(response =>
                response.offers
                    .map(rawOffer => new OfferDTO(rawOffer)));
    }

    /**
     * @param {OfferApiFiltersDTO} [filters]
     * @return {Promise<OfferDTO[]>}
     */
    async getAllOpenOffers(filters) {
        const filtersDTO = _.cloneDeep(filters) || new OfferApiFiltersDTO();
        filtersDTO.offerStatuses = [OfferStatus.AVAILABLE];
        return await this.getOffers(filtersDTO);
    }

    /**
     * @param {String} creator
     * @param {OfferApiFiltersDTO} [filters]
     * @return {Promise<OfferDTO[]>}
     */
    async getAllOffersFrom(creator, filters) {
        const filtersDTO = _.cloneDeep(filters) || new OfferApiFiltersDTO();
        filtersDTO.creators = [creator];
        return await this.getOffers(filtersDTO);
    }

    /**
     * @param {String} taker
     * @param {OfferApiFiltersDTO} [filters]
     * @return {Promise<OfferDTO[]>}
     */
    async getAllOffersTakenBy(taker, filters) {
        const filtersDTO = _.cloneDeep(filters) || new OfferApiFiltersDTO();
        filtersDTO.takers = [taker];
        return await this.getOffers(filtersDTO);
    }

    /**
     * @param {String} enhancementId
     * @param {String} creator
     * @return {Promise<OfferDTO[]>}
     */
    async getAllEnhancementOpenOffersForFrom(enhancementId, creator) {
        const filtersDTO = new OfferApiFiltersDTO();
        filtersDTO.assetTypes = [AssetType.ENHANCEMENT];
        filtersDTO.offerStatuses = [OfferStatus.AVAILABLE];
        filtersDTO.assetIds = [enhancementId];
        filtersDTO.creators = [creator];
        return await this.getOffers(filtersDTO);
    }

    /**
     * @param {AssetType} assetType
     * @param {Number} assetId
     * @returns {Promise<OfferDTO[]>}
     */
    async getOffersFor(assetType, assetId) {
        const filtersDTO = new OfferApiFiltersDTO();
        filtersDTO.assetTypes = [assetType];
        filtersDTO.assetIds = [assetId];
        return await this.getOffers(filtersDTO);
    }

    /**
     * @param {Number[]} offerIds
     * @returns {Promise<OfferDTO[]>}
     */
    async getOffersForOfferIds(offerIds) {
        const filtersDTO = new OfferApiFiltersDTO();
        filtersDTO.offerIds = offerIds;
        return await this.getOffers(filtersDTO);
    }

    /**
     *
     * @param {Number} offerId
     * @param {OfferStatus} desiredStatus
     * @returns {Promise<*>}
     */
    async waitForOfferStatus(offerId, desiredStatus) {
        const maxWaitTime = 2 * 60 * 1000;   // wait for 2 min in total
        const waitTime = 2 * 1000;       // check every 2 seconds
        const waitLoops = maxWaitTime / waitTime;

        for (let i = 0; i < waitLoops; i++) {
            await (new Promise(resolve => {
                setTimeout(resolve, waitTime)
            }));

            const offerDTOS = await this.getOffersForOfferIds([offerId]);
            const isInDesiredStatus = offerDTOS.at(0)?.status === desiredStatus;

            if (isInDesiredStatus) {
                return;
            }
        }
        throw new Error("Waiting for offer update exceeded time limit")
    }
};

<template>
    <div class="d-block w-100">
        <main-button :on-click="openPopup" class="open-popup-btn">
            <span class="d-none d-sm-flex">Cancel offer</span>
            <span class="d-flex d-sm-none">Cancel</span>
        </main-button>

        <dialog-box
            :model="popupOpen"
            :title="`Cancel ${assetType.toLowerCase()} offer`"
            width="500"
            v-on:dialogClosed="popupOpen=!popupOpen"
        >
            <v-form v-model="isFormValid">
                <div class="mt-5">
                    <v-row no-gutters>
                        <v-col cols="12" sm="5">
                            <div class="d-flex justify-center">
                                <mini-wizard
                                    v-if="assetType === assetTypeEnum.WIZARD"
                                    :item="assetDTO"
                                />
                                <mini-land
                                    v-else-if="assetType === assetTypeEnum.LAND"
                                    :item="assetDTO"
                                />
                                <mini-enhancement
                                    v-else-if="assetType === assetTypeEnum.ENHANCEMENT"
                                    :item="assetDTO"
                                    :amount="activeOffer.amount"
                                />
                            </div>
                        </v-col>
                        <v-col class="text-center align-center" cols="12" sm="7">
                            <v-container class="caption">
                                <v-row v-for="prop in offerProperties"
                                       class="text-left" dense>
                                    <v-col cols="4">{{ prop.name }}</v-col>
                                    <v-col class="font-weight-bold">{{ prop.value }}</v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </div>
                <main-button
                    :disabled="!isWalletConnected || !isFormValid"
                    :loading="transactionInProgress"
                    :on-click="()=>handleConfirmationClick()"
                    class="mt-4"
                >
                    Cancel offer
                </main-button>
            </v-form>
        </dialog-box>
    </div>
</template>

<script>

import MainButton from "../ui/main-button";
import DialogBox from "../dialog-box";
import MiniEnhancement from "../assets/enhancements/mini-enhancement";
import InputAmount from "../input-amount";
import MiniWizard from "../assets/wizards/mini-wizard";
import {AssetType} from "@/classes/asset/AssetType";
import MiniLand from "@/components/assets/lands/mini-land";
import MarketService from "@/services/marketService";
import OfferDTO from "@/classes/market/OfferDTO";
import MarketContract from "@/services/contracts/marketContract";
import AssetService from "@/services/assetService";
import {OfferStatus} from "@/classes/market/OfferStatus";
import {UserWalletError} from "../../errors/WalletErrors";

export default {
    components: {MiniLand, MiniWizard, InputAmount, MiniEnhancement, DialogBox, MainButton},
    props: {
        activeOffer: OfferDTO
    },
    data() {
        return {
            /**
             * @type {MarketContract}
             */
            marketContract: undefined,
            decimalPrecision: 100,
            assetService: undefined,
            marketService: undefined,
            assetTypeEnum: AssetType,
            marketFee: undefined,
            transactionInProgress: false,
            popupOpen: false,
            isFormValid: false,
        }
    },
    async mounted() {
        if (this.isWalletConnected) {
            this.init();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.init();
            });
        }
    },
    computed: {
        assetDTO() {
            return this.activeOffer.assetDTO
        },
        assetType() {
            return this.activeOffer.type
        },
        marketFeePercentage() {
            return (this.marketFee * 100) || 0;
        },
        amountRelevant() {
            return [AssetType.ENHANCEMENT].includes(this.assetType);
        },
        amountAsNumber() {
            return Number(this.activeOffer.amount);
        },
        sellPriceAsNumber() {
            return Number(this.activeOffer?.price || 0)
        },
        sellerProfit() {
            return this.sellPriceAsNumber * (1 - this.marketFee);
        },
        roundedSellerProfit() {
            return Math.round(this.sellerProfit * this.decimalPrecision) / this.decimalPrecision
        },
        treasuryProfit() {
            return this.sellPriceAsNumber * this.marketFee;
        },
        roundedTreasuryProfit() {
            return Math.round(this.treasuryProfit * this.decimalPrecision) / this.decimalPrecision
        },
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        offerProperties() {
            return [
                this.amountRelevant && {name: 'Amount', value: `${this.amountAsNumber.toLocaleString()} pcs`},
                {name: 'Price', value: `${this.$options.filters.localFormatNumber(this.sellPriceAsNumber)} SCRL`},
                {name: 'Fee', value: `${this.marketFeePercentage.toLocaleString()} %`},
                {name: 'You Get', value: `${this.roundedSellerProfit.toLocaleString()} SCRL`},
            ]
        },
    },
    methods: {
        init() {
            this.initializeAssetService()
            this.initializeMarketService()
            this.initializeMarketContract();
            this.marketFee = this.marketService.getMarketFee();
        },
        initializeAssetService() {
            this.assetService = new AssetService(this.web3, this.flashMessage);
        },
        initializeMarketService() {
            this.marketService = new MarketService();
        },
        initializeMarketContract() {
            this.marketContract = new MarketContract(this.web3, this.assetService);
        },
        openPopup() {
            this.popupOpen = true;
        },
        async handleConfirmationClick() {
            this.transactionInProgress = true;

            this.marketContract.cancelOffer(this.activeOffer.id)
                .then(async (_) => {
                    await this.marketService.waitForOfferStatus(
                        this.activeOffer.id,
                        OfferStatus.USER_CANCELLED);
                    this.flashMessage.show({
                        status: 'success',
                        message: 'Offer cancelled'
                    });
                    const loadedEvent = new CustomEvent('reload-view');
                    window.dispatchEvent(loadedEvent);
                    this.popupOpen = false;
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        console.error(error);
                        this.flashMessage.show({
                            status: 'error',
                            message: 'Offer cancellation failed'
                        });
                        this.popupOpen = false;
                    }
                })
                .finally(() => {
                    this.transactionInProgress = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.open-popup-btn.main-button {
}
</style>

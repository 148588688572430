<template>
    <div class="mini-item mini-enhancement" :data-id="item.id">
        <v-tooltip right max-width="400" content-class="mini-tooltip">
            <template v-slot:activator="{ on, attrs }">
                <div class="item-box"
                     v-bind="attrs"
                     v-on="on"
                >
                    <div class="item-header">
                        <span>{{ item.name }}</span>
                    </div>
                    <div class="item-content">
                        <img :src="item.thumbnail_82" class="thumbnail-img"/>
                        <div v-if="!hideAmount" class="item-badge">
                            {{ amountToDisplay }}x
                        </div>
                    </div>
                </div>
            </template>
            <span class="tooltip-content" v-if="item.name">
                <span class="tooltip-title">{{ item.name }}</span>
                <div class="d-flex flex-row align-start justify-start">
                    <img :src="item.thumbnail_82" class="mr-2"/>
                    {{ item.description }}
                </div>
            </span>
        </v-tooltip>
    </div>
</template>

<script>

export default {
    props: {
        item: Object,
        hideAmount: {
            type: Boolean,
            default: false,
        },
        amount: {
            type: Number,
            default: undefined,
        }
    },
    data() {
        return {}
    },
    created() {
    },
    computed: {
        amountToDisplay() {
            return this.amount || this.item.amount
        },
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.mini-item {
    .item-box {
        img.thumbnail-img {
            margin-top: 62px;
            width: 82px;
            object-fit: contain;
        }
    }
}
</style>
